import api from '../../api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormMode, type FormState, type FormTab, type Props } from './types';
import utils from '../../utils';
import { omit, random, set } from 'lodash';
import useModalLayer from '../ModalLayer';
import { Form, useNavigate } from 'react-router-dom';

const defaultState: FormState = {
    aspectRatio: '1:1',
    version: '1',
    age: 'adult',
    gender: Math.random() >= 0.5 ? 'female' : 'male',
};
const formStateStorage = utils.storage.access<FormState>('generate-form-state');
const nonSaveableStateFields = ['file', 'seed', 'version'];

export const limitLabels: { [key: string]: string } = {
    limitParallel:
        'Boost your productivity! Upgrade to a higher-tier plan for more simultaneous generations and faster results.',
    limitHour:
        "You’re on fire! You've hit the limit of fast generations on your current plan. Upgrade now to keep your workflow fast and seamless.",
    limitOverall: 'Take It to the Next Level – Choose Your Plan!',
    unsupportedRequest: 'This product is too complicated to be generated',
};

const getDefaultState = (): FormState => {
    const state = formStateStorage.get();
    if (!state) return defaultState;

    return {
        ...defaultState,
        ...omit(state, nonSaveableStateFields),
    };
};

const useLogic = (props: Props) => {
    const fileUploadAPI = api.operation.useFileUpload();
    const operationsAPI = api.operation.useOperations();
    const stacksAPI = api.stack.useStacks();

    const modalLayer = useModalLayer();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [state, setState] = useState<FormState>(getDefaultState());
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [tab, setTab] = useState<FormTab>('apparel');
    const [mode, setMode] = useState<FormMode>(FormMode.selection);

    const canGenerate = useMemo(
        () => !loading && (!!state.file || (mode === 'text-to-image' && !!state.prompt)),
        [loading, state, mode],
    );

    const onChangeState = useCallback(
        (update: Partial<FormState>) => setState((state) => ({ ...state, ...update })),
        [],
    );
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onGenerate = useCallback(async () => {
        // if (!state.file) return;

        setLoading(true);

        try {
            let productImageUrl: string | undefined = undefined;
            if (mode === FormMode.apparelToImage || mode === FormMode.photoEditor) {
                const urls = await fileUploadAPI.getFileUploadDetails();
                await fetch(urls.uploadUrl, {
                    body: state.file,
                    headers: { 'Content-Type': state.file!.type },
                    method: 'PUT',
                });
                productImageUrl = urls.downloadUrl;
            }

            const parameters: { [key: string]: string | undefined } = {
                ethnicity: state.model,
                age: state.model ? state.age : undefined,
                gender: state.model ? state.gender : undefined,
                angle: state.angle,
                background: state.background,
                hairColor: state.hairColor,
                photoType: state.photoType,
                skinTone: state.skinTone,
            };

            Object.keys(parameters).forEach((key) => {
                if (!parameters[key]) delete parameters[key];
            });

            const amount = mode === FormMode.photoEditor ? 1 : undefined;
            const makeResult = async () => {
                if (mode === FormMode.apparelToImage) {
                    return operationsAPI.generate({
                        aspectRatio: state.aspectRatio,
                        parameters: Object.keys(parameters).length ? parameters : undefined,
                        productImageUrl,
                        prompt: state.prompt,
                        seed: state.seed ?? Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
                        version: state.version ?? '1',
                    });
                }

                if (mode === FormMode.textToImage) {
                    return operationsAPI.imagine({
                        aspectRatio: state.aspectRatio,
                        prompt: state.prompt,
                        seed: state.seed ?? Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
                    });
                }

                if (mode === FormMode.photoEditor) {
                    return operationsAPI.upload({
                        imageUrl: productImageUrl!,
                    });
                }

                throw new Error('Invalid mode');
            };

            const operationResult = await makeResult();

            if (!operationResult.success || !operationResult.data) {
                if (operationResult.failureReason === 'unsupportedRequest')
                    return modalLayer.showDialog({
                        actions: [{ name: 'Got it', onClick: () => {} }],
                        text: limitLabels[operationResult.failureReason],
                        title: "We can't process this product",
                    });

                return modalLayer.showDialog({
                    actions: [{ name: 'Get subscription', onClick: () => navigate('/subscriptions') }],
                    text: limitLabels[operationResult.failureReason!],
                    title: "Let's upgrade your subscription",
                });
            }

            await stacksAPI.createMany({ amount, generateOperationId: operationResult.data.id });
        } finally {
            setLoading(false);
            handleClose();
        }
    }, [handleClose, state, mode]);

    const onOpen = useCallback(() => setOpen(true), []);
    const onReset = useCallback(() => {
        setTab('apparel');
        setMode(FormMode.selection);
        setState(defaultState);
    }, []);

    useEffect(() => {
        formStateStorage.set(omit(state, nonSaveableStateFields));
    }, [state]);

    useEffect(() => {
        if (props.controllerRef) props.controllerRef.current = { open: onOpen };
        return () => {
            if (props.controllerRef) props.controllerRef.current = { open: () => {} };
        };
    }, [onOpen]);

    return {
        canGenerate,
        loading,
        onChangeState,
        handleClose,
        onGenerate,
        onOpen,
        onReset,
        open,
        state,
        tab,
        setTab,
        mode,
        setMode,
        anchorEl,
        setAnchorEl,
    };
};

export default useLogic;
